<template>
  <div id="sum" style="display: flex">
    <div id="aside">
      <el-button
        type="primary"
        id="newButton"
        icon="el-icon-plus"
        @click="linkCreate"
      >
        <span style="font-weight: bold; font-size: 16px">New</span>
      </el-button>
      <el-col :span="12" id="list">
        <el-menu
          id="item"
          default-active="7"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            <i class="el-icon-document"></i>
            <span slot="title">All Questionnaire</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-document"></i>
            <span slot="title">All Industry</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-document"></i>
            <span slot="title">All Company Type</span>
          </el-menu-item>
          <el-menu-item index="7">
            <i class="el-icon-document"></i>
            <span slot="title">All Submissions</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i class="el-icon-setting"></i>
            <span slot="title">Set Used Questionnaire</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="el-icon-setting"></i>
            <span slot="title">Manage User License</span>
          </el-menu-item>
          <el-menu-item index="8">
            <i class="el-icon-setting"></i>
            <span slot="title">Add New User</span>
          </el-menu-item>
          <el-menu-item index="9">
            <i class="el-icon-setting"></i>
            <span slot="title">Add New Consultant</span>
          </el-menu-item>
          <el-menu-item index="10">
            <i class="el-icon-setting"></i>
            <span slot="title">Manage Prompts</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </div>
    <div id="mainpage">
      <div id="title">
        <span style="margin-left: 35px">All Submissions</span>
      </div>

      <div id="main-body">
        <div>
          <el-card
            v-for="(msg, index) in userdata"
            class="box-card"
            :key="index"
            :body-style="{ padding: '0px 25px' }"
          >
            <div slot="header" style="display: flex">
              <el-row>
                <el-col :span="20">Submission Id: {{ msg.title }}</el-col>
                <el-col :span="20">Industry: {{ msg.industry_name }}</el-col>
                <el-col :span="20"
                  >Company Type: {{ msg.company_type_name }}</el-col
                >
                <el-col :span="20"
                  >Questionnaire ID: {{ msg.fk_questionnaire_id }}</el-col
                >
                <el-col :span="20">User Email: {{ msg.user_email }}</el-col>
                <el-col :span="20">State: {{ msg.state }}</el-col>
                <el-col :span="20">Report State: {{ msg.report_state }}</el-col>
                <el-col :span="20">Case ID: {{ msg.case_id }}</el-col>
              </el-row>
              <span class="headspan">Create_Time: {{ msg.create_time }}</span>
              <span class="headspan">Update_Time: {{ msg.update_time }}</span>
            </div>
            <div slot="default" class="card-body">
              <el-button
                type="text"
                class="rightside el-icon-receiving"
                @click="resubmit(msg.title)"
                >&nbsp;Re-Submit</el-button
              >
              <el-button
                type="text"
                class="rightside el-icon-document"
                @click="checkLog(msg.title)"
                >&nbsp;Check Log</el-button
              >
              <el-button
                type="text"
                class="rightside el-icon-document"
                @click="uploadFile(msg.title)"
                >&nbsp; Upload Full Report Data</el-button
              >
              <el-button
                v-show="msg.report_state == 'finished'"
                type="text"
                class="rightside el-icon-document"
                @click="openReportLanguageDialog(msg)"
                >&nbsp; Check Report</el-button
              >
              <el-button
                v-show="msg.report_state == 'finished'"
                type="text"
                class="rightside el-icon-document"
                @click="openEditReportContenteDialog(msg)"
                >&nbsp; Edit Report Content</el-button
              >
              <el-button
                type="text"
                class="rightside el-icon-document"
                @click="editUserInput(msg)"
                >&nbsp; Edit User Input</el-button
              >
              <input
                @change="(event) => fileInput(event, msg)"
                type="file"
                accept=".xlsx"
                id="fileInput"
                style="display: none"
              />
            </div>
          </el-card>

          <el-pagination
            layout="sizes, prev, pager, next, jumper"
            background
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :total="QnList.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            style="margin: 20px"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        title="Report Setting"
        :visible.sync="reportSettingDialogVisible"
        width="30%"
      >
        <el-form :model="reportSettingDialogForm" ref="reportSettingDialogForm">
          <el-form-item
            label="Currency Unit"
            label-width="120px"
            prop="currency"
          >
            <el-select
              v-model="reportSettingDialogForm.currency"
              placeholder=""
              filterable
            >
              <el-option label="CNY" value="CNY"></el-option>
              <el-option label="USD" value="USD"></el-option>
              <el-option label="GBP" value="GBP"></el-option>
              <el-option label="EUR" value="EUR"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="language" label-width="120px" prop="language">
            <el-select
              v-model="reportSettingDialogForm.language"
              placeholder="Please select language"
              filterable
            >
              <el-option label="Chinese" value="zh"></el-option>
              <el-option label="English" value="en"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="reportSettingDialogVisible = false"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="
              confirmReportSetting(
                currentSubmissionId,
                reportSettingDialogForm.language,
                reportSettingDialogForm.currency
              )
            "
            >Confirm</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        title="Edit Report Content"
        :visible.sync="editReportContentDialogVisible"
        width="80%"
      >
        <vue-json-editor
          v-model="currentSubmissionObj"
          :options="editorOptions"
        ></vue-json-editor>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editReportContentDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="editReportContentDialogConfirm"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <SubmissionsReview
        v-if="reviewDialogVisible"
        :user_inputs="user_inputs"
        :lang="lang"
        :report_currency="report_currency"
        :currency="questionnaire_currency"
        :answer_address="answer_address"
        @close="handleClose"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import SubmissionsReview from "./SubmissionsReview.vue";
import VueJsonEditor from "vue-json-editor";

export default {
  components: {
    VueJsonEditor,
    SubmissionsReview,
  },
  created() {
    this.searchQns(0);
  },
  data() {
    return {
      temp_sid: "",
      reviewDialogVisible: false,
      user_inputs: null,
      lang: "EN",
      report_currency: "USD",
      questionnaire_currency: "USD",
      answer_address: [],
      editReportContentDialogVisible: false,
      currentCardId: null,
      currentSubmissionObj: null,
      editorOptions: {
        mode: "code", // 设置编辑模式，其他可选值比如 'tree', 'form'
        mainMenuBar: true,
        navigationBar: true,
        statusBar: true,
      },
      reportSettingDialogVisible: false,
      currentSubmissionId: "",
      reportSettingDialogForm: {
        currency: "GBP",
        language: "en",
      },
      submission_id: "",
      currentPage: 1,
      pageSize: 10,
      pageSizes: [5, 10, 15, 20],
      activeIdx: "7",

      qnKey: "",
      orderItem: "default",
      orderType: "default",
      hasQn: true,
      input: "",
      is_released: "default",
      is_collected: 0,
      qn_type: 0,
      QnList: [],
    };
  },
  methods: {
    handleSubmit(user_inputs, model_inputs, report_curr) {
      console.log(user_inputs);
      console.log(model_inputs);
      console.log(report_curr);

      this.$axios({
        method: "PUT",
        url: `https://api.oxvalue.cn/api/question/v0.1/submission_admin/${this.temp_sid}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
        data: JSON.stringify({
          user_inputs: JSON.stringify(user_inputs),
          model_inputs: JSON.stringify(model_inputs),
          report_curr: report_curr,
        }),
      }).then((res) => {
        console.log(res);
        this.handleClose();
      });
    },
    handleClose() {
      this.reviewDialogVisible = false;
      // 销毁组件并且将这些参数设置为默认值。
      this.user_inputs = null;
      this.lang = "EN";
      this.report_currency = "USD";
      this.questionnaire_currency = "USD";
      this.answer_address = [];
    },
    editUserInput(msg) {
      // 获取到msg--当前case的提交信息
      console.log(msg);
      this.temp_sid = msg.title;
      // 根据sid发api得到它的user_inputs对象，和语言lang 和其他需要的参数。
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.cn/api/question/v0.1/submission_admin/${msg.title}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        console.log(res);
        // 设置review组件需要的参数
        this.user_inputs = JSON.parse(
          res.data.data.user_inputs
        ).company_information;
        this.lang = res.data.data.config.chat_log_description.language
          ? res.data.data.config.chat_log_description.language.toLocaleUpperCase()
          : "ZH";
        this.report_currency = res.data.data.config.report_currency;
        this.questionnaire_currency = res.data.data.config.currency;
        this.answer_address =
          res.data.data.config.chat_log_description.answer_address;
        // 因为review组件是由v-if控制的，当它设置为true是，组件被创建。变成false时组件被销毁
        this.reviewDialogVisible = true;
      });
    },
    editReportContentDialogConfirm() {
      console.log(this.currentSubmissionObj);
      this.$axios({
        method: "PUT",
        url: `https://api.oxvalue.cn/api/question/v0.1/submission_admin/${this.currentCardId}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
        data: JSON.stringify({
          charts: JSON.stringify(this.currentSubmissionObj.charts),
          result: JSON.stringify(this.currentSubmissionObj.result),
          result2: JSON.stringify(this.currentSubmissionObj.report_en),
          result2_zh: JSON.stringify(this.currentSubmissionObj.report_zh),
          report_full_en: JSON.stringify(
            this.currentSubmissionObj.report_full_en
          ),
          report_full_zh: JSON.stringify(
            this.currentSubmissionObj.report_full_zh
          ),
          report_currency: this.currentSubmissionObj.config.report_currency,
          consultant_name: this.currentSubmissionObj.config.applicant_name,
          stamp: this.currentSubmissionObj.config.stamp,
          is_locked: this.currentSubmissionObj.is_locked,
        }),
      }).then((res) => {
        console.log(res);
      });

      this.currentSubmissionObj = null;
      this.currentCardId = null;
      this.editReportContentDialogVisible = false;
    },
    openEditReportContenteDialog(msg) {
      console.log(msg);
      this.currentCardId = msg.title;
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.cn/api/question/v0.1/submission_admin/${msg.title}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        console.log(res.data.data.config);
        let report_data = {
          config: {},
        };
        report_data.result = JSON.parse(res.data.data.result);
        report_data.report_en = JSON.parse(res.data.data.result2);
        report_data.report_zh = JSON.parse(res.data.data.result2_zh);
        report_data.report_full_en = res.data.data.report_full_en
          ? JSON.parse(res.data.data.report_full_en)
          : "";
        report_data.report_full_zh = res.data.data.report_full_zh
          ? JSON.parse(res.data.data.report_full_zh)
          : "";
        report_data.charts = JSON.parse(res.data.data.charts);
        report_data.config.report_currency =
          res.data.data.config.report_currency;
        report_data.config.consultant_name =
          res.data.data.config.consultant_name;
        report_data.config.stamp = res.data.data.config.stamp + "";
        report_data.is_locked = res.data.data.config.is_locked + "";

        this.currentSubmissionObj = report_data;
      });
      this.editReportContentDialogVisible = true;
    },
    openReportLanguageDialog(msg) {
      console.log(msg);
      this.currentSubmissionId = msg.title;
      this.reportSettingDialogForm.currency = msg.currency;
      this.reportSettingDialogVisible = true;
    },
    confirmReportSetting(id, language, currency) {
      let token = window.localStorage.getItem("ova_admin_token");
      if (language == "zh") {
        window.open(
          `https://eva.oxvalue.cn/report_new_zh.html?sid=${id}&curr=${currency}&mode=admin&token=${token}`
        );
      } else if (language == "en") {
        window.open(
          `https://eva.oxvalue.cn/report_new.html?sid=${id}&curr=${currency}&mode=admin&token=${token}`
        );
      }
      this.reportSettingDialogVisible = false;
    },
    uploadFile(id) {
      this.submission_id = id;
      document.getElementById("fileInput").click();
    },
    fileInput(e) {
      const file = e.target.files[0]; // 获取用户选择的文件
      console.log(file);
      console.log("用户选择了文件：", file.name);
      console.log(this.submission_id);

      const formData = new FormData();
      formData.append("file", file); // 'file' 是你的服务器期望的字段名

      this.$axios({
        method: "post",
        url: `https://api.oxvalue.cn/api/question/v0.1/full_report_file/${this.submission_id}/ `,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then(function(response) {
          console.log(response);
          if (response.data.code == 200) {
            alert(`上传成功`);
          } else if (response.data.code == -1) {
            alert(`上传失败：` + response.data.error);
          }
        })
        .catch(function(error) {
          console.error("上传失败:", error);
        });
    },
    linkCreate() {
      this.$router.push("/create_ques");
    },
    indexMethod(index) {
      return (this.currentPage - 1) * this.pageSize + index;
    },
    handleSizeChange(val) {
      console.log(`${val} Each Page`);
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      console.log(`Current Page: ${val}`);
      this.currentPage = val;
    },
    beforeHandleCommand(index, format) {
      return {
        index: index,
        format: format,
      };
    },
    handleSelect(key) {
      this.activeIdx = key;
      switch (this.activeIdx) {
        case "1":
          this.$router.push("/index");
          break;
        case "2":
          this.$router.push("/industry");
          break;
        case "3":
          this.$router.push("/bin");
          break;
        case "4":
          this.$router.push("/company");
          break;
        case "5":
          this.$router.push("/used_questionnaire");
          break;
        case "6":
          this.$router.push("/user_license");
          break;
        case "7":
          this.$router.push("/submissions");
          break;
        case "8":
          this.$router.push("/add_new_user");
          break;
        case "9":
          this.$router.push("/add_new_consultant");
          break;
        case "10":
          this.$router.push("/manage_prompts");
          break;
      }
    },
    searchQn() {
      this.qnKey = this.input;
      this.searchQns(1);
    },
    searchQns(tag) {
      console.log(tag);
      this.$axios({
        method: "get",
        url: "https://api.oxvalue.cn/api/question/v0.1/submission_admin/",
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      })
        .then((res) => {
          if (res.data["code"] == 403) {
            this.$store.dispatch("clear");
            window.localStorage.removeItem("ova_admin_token");
            this.$router.push("/login");
            return;
          }
          this.QnList = [];
          let qlist = res.data["data"]["submission_list"];
          console.log(qlist);

          for (let q in qlist) {
            let question = {
              title: qlist[q]["id"],
              survey_id: qlist[q]["id"],
              create_time: qlist[q]["create_time"],
              update_time: qlist[q]["update_time"],
              currency: qlist[q]["currency"],
              industry_name: qlist[q]["industry_name"],
              company_type_name: qlist[q]["company_type_name"],
              fk_questionnaire_id: qlist[q]["fk_questionnaire_id"],
              fk_user_id: qlist[q]["fk_user_id"],
              user_email: qlist[q]["user_email"],
              user_name: qlist[q]["user_name"],
              state: qlist[q]["state"],
              report_state: qlist[q]["report_state"],
              case_id: qlist[q]["case_id"],
            };
            this.QnList.push(question);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resubmit(index) {
      let post_data = {};
      this.$confirm(
        "<p>This will submit the original answers again, do you want to do this?</p>" +
          '<label><input type="checkbox" id="stablePromptCheckbox"> 是否使用不稳定prompts</label>',
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              const checkbox = document.getElementById("stablePromptCheckbox");
              post_data.stable_flag = (!checkbox.checked).toString();
            }
            done();
          },
        }
      )
        .then(() => {
          this.$axios({
            method: "POST",
            url: `https://api.oxvalue.cn/api/question/v0.1/resubmission_admin/${index}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: post_data,
          }).then((res) => {
            if (res.data.code == "200") {
              this.$message.success("Re-submitted successfully.");
            } else {
              this.$message.error(res.data.error);
            }
          });
        })
        .catch(() => {
          // 用户取消操作
        });
    },
    checkLog(index) {
      this.$router.push(`/log?sid=${index}`);
    },
  },
  computed: {
    userdata: function() {
      return this.QnList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
};
</script>

<style scoped>
#sum {
  /*height: 1000px;*/
  width: 100%;
  min-width: 1200px;
  /* background-color: rgb(241, 241, 241); */
}
#aside {
  width: 340px;
  /* background-color: antiquewhite; */
  min-width: 325px;
}
#mainpage {
  width: 70%;
  /* background-color: aquamarine; */
  min-width: 850px;
}
#newButton {
  width: 60%;
  height: 50px;
  font-size: 15px;
  margin-top: 25px;
}
#list {
  width: 100%;
  margin-top: 25px;
}
#list .el-menu {
  width: 240px;
  margin: 0 54px;
}
#list .el-menu-item {
  height: 50px;
  font-size: 14px;
  text-align: center;
}

#title {
  height: 35px;
  font-size: 22px;
  text-align: left;
  margin: 15px;
  font-weight: bold;
}
.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 6px;
}
#title .el-input {
  width: 200px;
}
.right {
  float: right;
  margin: 0 15px;
  /* margin-top: 10px; */
  padding: 13px 0;
}

.box-card {
  margin: 25px 50px;
  width: 89%;
  height: auto;
  font-size: 12px;
}
.el-col-20 {
  text-align: left;
  font-size: 16px;
  padding: 5px 15px;
  width: 520px;
  /* font-weight: bold; */
}
.headspan {
  padding: 7px 7px;
}
.leftside {
  float: left;
  padding: 10px;
}
.rightside {
  float: right;
  padding: 10px;
  color: black;
  font-size: 14px;
}
#download {
  padding: 3px 10px 12px 10px;
}

#newButton {
  width: 240px;
  height: 56px;
}

.el-icon-my-star {
  background: url("/src/assets/icon/star.png") center no-repeat;
  background-size: cover;
  font-family: element-icons;
}
.el-icon-my-star:before {
  font-size: 13px;
  content: "66ff";
  visibility: hidden;
  font-family: element-icons, serif !important;
}
</style>
,,,
